<template>
  <div class="pageMain">
    <search-head :model="searchForm" @search="search" @reset="reset">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="设备类型">
          <el-select
                v-model="searchForm.deviceType"
                size="small"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in deviceTypeList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select
            v-model="searchForm.deviceOnline"
            size="small"
            placeholder="状态"
          >
            <el-option label="在线" value="0"></el-option>
            <el-option label="离线" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备编号">
          <el-input
            v-model="searchForm.deviceImei"
            size="small"
            placeholder="设备编号"
          ></el-input>
        </el-form-item>
      </el-form>
    </search-head>
    <custom-tables
      :data="tableData"
      :headerCellStyle="headerCellStyle"
      @selectionChange="handleSelectionChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      height="55vh"
      :total="total"
      title="项目设备"
    >
      <!-- <template slot="btns">
          <el-button type="primary" size="small" @click="addFrom">新建</el-button>
        </template> -->
      <el-table-column type="selection" align="center" width="55">
      </el-table-column>
      <el-table-column prop="deviceType" label="设备类型" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.deviceType == 0">塔吊</span>
          <span v-if="scope.row.deviceType == 1">升降机</span>
          <span v-if="scope.row.deviceType == 2">龙门吊</span>
          <span v-if="scope.row.deviceType == 3">架桥机</span>
          <span v-if="scope.row.deviceType == 4">爬模</span>
          <span v-if="scope.row.deviceType == 5">挂篮</span>
          <span v-if="scope.row.deviceType == 8">扬尘</span>
          <span v-if="scope.row.deviceType == 9">喷淋</span>
        </template>
      </el-table-column>
      <el-table-column prop="deviceImei" align="center" label="现场编号/设备ID">
      </el-table-column>
      <el-table-column prop="deviceOnline" align="center" label="在线状态">
        <template slot-scope="scope">
              <el-tag v-if="scope.row.deviceOnline == 0" type="success"
                >在线
              </el-tag>
              <el-tag v-if="scope.row.deviceOnline == 1" type="danger"
                >离线</el-tag
              >
            
            </template>
      </el-table-column>
      <el-table-column label="详情" align="center" width="100">
        <template slot-scope="scope">
          <span @click="detailItem(scope.row)" class="detail">查看</span>
        </template>
      </el-table-column>
    </custom-tables>

    <custom-dialog
      :visible="showDialog"
      title="新增控制"
      @close="close"
      @submit="submit"
    >
      <div>弹出框</div>
    </custom-dialog>
  </div>
</template>

<script>
import SearchHead from "../../../components/common/SearchHead";
import CustomTables from "../../../components/common/CustomTables";
import CustomDialog from "../../../components/common/CustomDialog";
import data from "../../../api/modules/data";
import {equipType} from '../../../config/dataStatus'
export default {
  name: "index",
  components: { CustomDialog, CustomTables, SearchHead },
  data() {
    return {
      tableData: [],
      searchForm: {
        currPage: 1,
        pageSize: 10,
      },
      total: 0,
      showDialog: false,
      headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      deviceTypeList:equipType
    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      data.getDeviceReportListByPage(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.records;
      });
    },
    search() {
      console.log("searchForm", this.searchForm);
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = 10;
      this.getList();
    },
    reset() {
      this.searchForm = {
        currPage: 1,
        pageSize: 10,
       
      };
      this.getList();
    },
    addFrom() {
      this.showDialog = true;
    },
    close() {
      this.showDialog = false;
    },
    submit() {
      this.showDialog = false;
    },
    detailItem() {
      //详情
    },
    handleSelectionChange(val) {
      console.log(val);
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.currPage = 1
      this.getList()
    },
  },
};
</script>

<style scoped lang="less">
.detail {
  color: #008f4d;
}
</style>
